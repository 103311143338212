<template>
  <section class="cont">
    <!-- 面包屑  -->
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>AIOT</el-breadcrumb-item>
        <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
        <el-breadcrumb-item to="/hotel_manage">酒店列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ action === 'add' ? '新建' : '编辑' }}酒店</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <!-- 返回页头 -->
    <el-page-header @back="goBack" :content="`${ action === 'add' ? '新建' : '编辑' }酒店`"></el-page-header>
    <el-row class="cont-form-box">
      <!-- 步骤条 -->
      <el-steps :active="active" simple>
        <el-step title="基本信息"></el-step>
        <el-step title="详细信息"></el-step>
      </el-steps>
      <!-- 表单 -->
      <el-form class="form-box" :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="所属单位" prop="companyIds">
          <el-cascader v-model="ruleForm.companyIds" :props="companies" :show-all-levels="false"></el-cascader>
        </el-form-item>
        <el-form-item label="酒店名称" prop="hotelName">
          <el-input class="m-right-10" v-model="ruleForm.hotelName" :placeholder="$t('msg.hotel_name')" clearable></el-input>
          <el-input class="width-120" v-model="ruleForm.hotelAlias" :placeholder="$t('msg.hotel_alias')" clearable></el-input>
        </el-form-item>
        <el-form-item label="酒店编码" prop="hotelCode">
          <el-input v-model="ruleForm.hotelCode" :placeholder="$t('msg.hotel_code')" clearable></el-input>
        </el-form-item>
        <el-form-item label="酒店类型" prop="hotelType">
          <el-select v-model="ruleForm.hotelType" :placeholder="$t('msg.select')" clearable>
            <el-option v-for="item in hotelTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="酒店LOGO">
          <el-upload class="avatar-uploader" ref="upload" :show-file-list="false" action="#" :auto-upload="false" :on-change="handleUpload">
            <img alt="" v-if="hotelLogo" :src="hotelLogo" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="联系电话" prop="telephone">
          <el-input v-model="ruleForm.telephone" :placeholder="$t('msg.contact_phone')" clearable></el-input>
        </el-form-item>
        <el-form-item label="传真地址">
          <el-input class="width-120 m-right-10" v-model="ruleForm.areaCode" :placeholder="$t('msg.area_code')" maxLength='4' clearable></el-input>
          <el-input v-model="ruleForm.faxNumber" :placeholder="$t('msg.fax_number')" maxLength='15' clearable></el-input>
        </el-form-item>
        <el-form-item label="省市区" prop="addressIds">
          <el-cascader @change="handleChange" v-model="ruleForm.addressIds" :props="addressProp"></el-cascader>
        </el-form-item>
        <el-form-item label="区域位置" prop="street">
          <el-input class="m-right-10" v-model="ruleForm.street" :placeholder="$t('msg.subdistrict_office')" clearable></el-input>
          <span style="color: rgb(8, 127, 253)" @click="visibleMap = true"><i style="font-size: 25px" class="el-icon-location"></i>标注位置</span>
        </el-form-item>
        <el-form-item label="经纬度" prop="latitude">
          <span class="m-right-30">经度：{{ ruleForm.latitude }}</span><span>纬度：{{ ruleForm.longitude }}</span>
        </el-form-item>
        <el-form-item label="标签">
          <el-tag :key="tag" v-for="tag in tags" closable :disable-transitions="false" @close="closeTag(tag)">
            {{tag}}
          </el-tag>
          <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" @keyup.enter.native="inputConfirm" @blur="inputConfirm">
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增</el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="bg-gradient" @click="handleSave" v-text="$t('msg.save')">保存</el-button>
          <el-button v-if="action === 'edit'" @click="nextStep">下一步</el-button>
        </el-form-item>
      </el-form>
      <!-- 地图弹窗 -->
      <el-dialog title="地图标注" width="80%" :visible.sync="visibleMap">
        <baidu-map class="baidu-map-view" ak="mNfWoetj2YgzZnGqhQilFgZI1Kw9zAc2" :center="address" :zoom="15" @click="clickMap" :scroll-wheel-zoom="true">
          <bm-marker :position="point" :dragging="true" animation="BMAP_ANIMATION_BOUNCE" @dragging="draggingLocator"></bm-marker>
          <bm-view style="width: 100%; height: 100%;"></bm-view>
          <bm-control :offset="{width: '10px', height: '10px'}">
            <bm-auto-complete v-model="keyword" :sugStyle="{zIndex: 1}">
              <el-input class="map-search" size="small" v-model="keyword" placeholder="请输入地名关键字"></el-input>
            </bm-auto-complete>
          </bm-control>
          <bm-local-search :keyword="keyword" :auto-viewport="true"></bm-local-search>
        </baidu-map>
        <span slot="footer" class="dialog-footer">
          <el-button @click="visibleMap = false">取 消</el-button>
          <el-button type="primary" @click="ruleForm.latitude = point.lat; ruleForm.longitude = point.lng; visibleMap = false">确 定</el-button>
        </span>
        经度：{{ point.lng }}&nbsp;纬度：{{ point.lat }}
      </el-dialog>
    </el-row>
  </section>
</template>
<script>
import { urlObj } from "@/api/interface";
import { system } from "@/api/interface/system";
import { verificationRule, beforeUpload } from "@/common/js/common";
export default {
  data() {
    let validTelephone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (verificationRule.mobile(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    let validtudeType = (rule, value, callback) => {
      if (this.ruleForm.latitude === "") {
        callback(new Error("经纬度不能为空"));
      } else if (this.ruleForm.latitude === "") {
        callback(new Error("经度不能为空"));
      } else if (this.ruleForm.longitude === "") {
        callback(new Error("纬度不能为空"));
      } else {
        callback();
      }
    };
    let _this = this;
    return {
      action: "", // 当前操作
      active: 0, // 进度条状态
      companies: {
        // 公司列表
        lazy: true,
        checkStrictly: true,
        async lazyLoad(node, resolve) {
          const { value, hasChildren } = node;
          // 如果没有子节点则无需发起请求
          if (hasChildren === false) return resolve([]);
          let data = await _this.getCompanies(value);
          resolve(data);
        },
      },
      hotelTypes: [
        // 酒店类型
        {
          value: "DIRECT_SALE_STORE",
          label: "直营店",
        },
        {
          value: "FRANCHISEE",
          label: "加盟店",
        },
      ],
      addressProp: {
        // 地域列表
        lazy: true,
        checkStrictly: true,
        async lazyLoad(node, resolve) {
          let { value, hasChildren, level } = node;
          if (hasChildren === false) return resolve([]);
          if (value) {
            value = value.split(",")[0];
          } else {
            value = -1;
          }
          let data;
          try {
            data = await _this.getRegionList(value, level);
          } catch (e) {
            console.error(e);
          }
          resolve(data);
        },
      },
      address: "北京市",
      zoom: 100,
      point: {
        lat: "", // 纬度
        lng: "", // 经度
      },
      keyword: "",
      visibleMap: false, // 地图弹窗
      tags: [], // 标签列表
      inputVisible: false,
      inputValue: "",
      ruleForm: {
        companyIds: [], // 租户ID,商户ID
        hotelName: "", // 酒店名称
        hotelAlias: "", // 酒店别名
        hotelType: "DIRECT_SALE_STORE", // 酒店类型
        hotelCode: "", // 酒店编码
        telephone: "", // 联系电话
        areaCode: "", // 传真区号
        faxNumber: "", // 传真号码
        street: "", // 街道
        addressIds: [], // 省市区
        latitude: "", // 纬度
        longitude: "", // 经度
      },
      id: "", // 编辑ID
      file: null, // 图片资源
      hotelLogo: "", // 酒店LOGO
      province: "", // 省
      city: "", // 市
      district: "", // 区
      rules: {
        companyIds: [
          { required: true, message: "请选择所属单位", trigger: "change" },
        ],
        hotelName: [
          { required: true, message: "请填写酒店名称", trigger: "blur" },
        ],
        hotelCode: [
          { required: true, message: "请填写酒店编码", trigger: "blur" },
        ],
        hotelType: [
          { required: true, message: "请填写酒店类型", trigger: "blur" },
        ],
        telephone: [
          { required: true, validator: validTelephone, trigger: "blur" },
        ],
        addressIds: [
          { required: true, message: "请选择省市区", trigger: "change" },
        ],
        street: [
          { required: true, message: "请填写区域位置", trigger: "blur" },
        ],
        latitude: [
          { required: true, validator: validtudeType, trigger: "change" },
        ],
      },
      /* 提示文本 */
      add_success: "添加成功！",
      update_success: "修改成功！",
      confirm: "确定",
      prompt: "提示！",
      confirm_edit: "确定修改？",
      confirm_remove: "确定移除？",
      remove_success: "移除成功！",
      bind_success: "绑定成功！",
    };
  },
  watch: {
    "ruleForm.companyIds"(val) {
      console.log(val);
      if (val.length > 1) {
        sessionStorage.setItem("companyId", val[1]);
      } else if (val.length === 1) {
        sessionStorage.setItem("companyId", val[0]);
      }
    },
  },
  mounted() {
    this.action = this.$route.query.action;
    this.action === "edit" && this.echoHotel();
  },
  beforeDestroy() {
    sessionStorage.removeItem("hotelObj");
  },
  methods: {
    // 编辑时回显数据
    echoHotel() {
      const hotelInfo = JSON.parse(sessionStorage.getItem("hotelObj"));
      this.id = hotelInfo.id;
      this.ruleForm.companyIds.push(hotelInfo.tenantId);
      console.log(this.ruleForm.companyIds);
      if (hotelInfo.storeId !== hotelInfo.tenantId)
        this.ruleForm.companyIds.push(hotelInfo.storeId);
      this.ruleForm.hotelName = hotelInfo.hotelName;
      this.ruleForm.hotelCode = hotelInfo.hotelCode;
      this.ruleForm.hotelAlias = hotelInfo.hotelAlias;
      this.ruleForm.hotelType = hotelInfo.hotelType;
      this.ruleForm.telephone = hotelInfo.telephone || "";
      this.hotelLogo = hotelInfo.hotelLogo || "";
      if (hotelInfo.fax) {
        this.ruleForm.areaCode = hotelInfo.fax.split("-")[0];
        this.ruleForm.faxNumber = hotelInfo.fax.split("-")[1];
      }
      if (hotelInfo.province) {
        this.province = hotelInfo.province;
        this.address = this.province.split(",")[1];
        this.ruleForm.addressIds.push(this.province);
      }
      if (hotelInfo.city) {
        this.city = hotelInfo.city;
        this.address = this.address + this.city.split(",")[1];
        this.ruleForm.addressIds.push(this.city);
      }
      if (hotelInfo.district) {
        this.district = hotelInfo.district;
        this.address = this.address + this.district.split(",")[1];
        this.ruleForm.addressIds.push(this.district);
      }
      this.ruleForm.street = hotelInfo.street || "";
      this.ruleForm.latitude = hotelInfo.latitude || "";
      this.ruleForm.longitude = hotelInfo.longitude || "";
      this.point.lat = hotelInfo.latitude || "";
      this.point.lng = hotelInfo.longitude || "";
      if (hotelInfo.tags) this.tags = hotelInfo.tags.split(",");
    },
    // 获取单位列表
    getCompanies(companyIds) {
      return new Promise((resolve, reject) => {
        const url = system.companies;
        const { accountType } = JSON.parse(sessionStorage.getItem("userInfo"));
        let param = {};
        if (accountType === "PLATFORM" || accountType === "TENANT")
          param.type = "TENANT";
        if (accountType === "STORE") param.type = "STORE";
        if (companyIds) (param.type = "STORE"), (param.tenantId = companyIds);
        this.$axios.post(url, param, "json").then((res) => {
          if (res.records) {
            let companies = res.records;
            companies = companies.map((item) => {
              return {
                value: item.id,
                label: item.companyName,
                leaf: item.leaf,
              };
            });
            resolve(companies);
          } else {
            reject(new Error("数据错误！"));
          }
        });
      });
    },
    // 获取上传的文件资源
    handleUpload(file) {
      let err = beforeUpload(file);
      if (err) return this.$message.error(err);
      this.hotelLogo = URL.createObjectURL(file.raw);
      this.file = file.raw;
    },
    // 获取省市县
    getRegionList(parentId, level) {
      return new Promise((resolve) => {
        const url = urlObj.regionList + `/${parentId}`;
        this.$axios.get(url, {}).then((res) => {
          if (res.success) {
            let addressList = res.children;
            addressList = addressList.map((item) => {
              if (level === 2)
                return {
                  value: item.id + "," + item.regionName,
                  label: item.regionName,
                  leaf: true,
                };
              return {
                value: item.id + "," + item.regionName,
                label: item.regionName,
              };
            });
            resolve(addressList);
          }
        });
      });
    },
    // 选择省市区
    handleChange(value) {
      let [province, city, district] = value;
      if (province) {
        this.province = province;
        this.address = this.province.split(",")[1];
      }
      if (city) {
        this.city = city;
        this.address = this.address + this.city.split(",")[1];
      }
      if (district) {
        this.district = district;
        this.address = this.address + this.district.split(",")[1];
      }
    },
    // 点击地图
    clickMap(target) {
      this.point = target.point;
    },
    // 拖动定位符
    draggingLocator(event) {
      this.point = event.point;
    },
    // 关闭标签
    closeTag(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },
    // 标签输入确认
    inputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 新增标签
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 处理保存
    handleSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let url = urlObj.addHotel;
          let param = new FormData();
          if (this.file) {
            param.append("file", this.file); // 文件
            param.append("module", "hotel"); // 所在模块
            param.append("menu", "list"); // 所属菜单
            param.append("func", "logo"); // 图片功能
            param.append("category", "logo"); // 图片分类
            param.append("isThumb", true); // 是否开启缩略图
          }
          param.append("createSource", "SASS");
          param.append("tenantId", this.ruleForm.companyIds[0]);
          param.append("storeId", this.ruleForm.companyIds[0]);
          if (this.ruleForm.companyIds.length > 1)
            param.set("storeId", this.ruleForm.companyIds[1]);
          param.append("hotelName", this.ruleForm.hotelName);
          param.append("hotelCode", this.ruleForm.hotelCode);
          param.append("hotelType", this.ruleForm.hotelType);
          param.append("hotelAlias", this.ruleForm.hotelAlias);
          param.append("telephone", this.ruleForm.telephone);
          param.append("province", this.province);
          param.append("city", this.city);
          param.append("district", this.district);
          param.append("street", this.ruleForm.street);
          param.append("latitude", this.ruleForm.latitude);
          param.append("longitude", this.ruleForm.longitude);
          param.append("tags", this.tags.toString());
          this.ruleForm.areaCode
            ? param.append(
                "fax",
                this.ruleForm.areaCode + "-" + this.ruleForm.faxNumber
              )
            : param.append("fax", this.ruleForm.faxNumber);
          if (this.action === "edit") {
            url = urlObj.modifyHotel;
            param.append("id", this.id);
          }
          this.$axios
            .post(url, param, "file")
            .then((res) => {
              if (res.success) {
                this.$message({
                  showClose: true,
                  message:
                    this.action === "add"
                      ? this.add_success
                      : this.update_success,
                  type: "success",
                });
                // 如果操作行为是新增 成功后自动下一步
                if (this.action === "add") {
                  this.id = res.records.id;
                  this.nextStep();
                }
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        }
      });
    },
    // 返回上页
    goBack() {
      this.$router.go(-1);
    },
    // 下一步
    nextStep() {
      this.$router.replace({
        path: "/hotel_add_detail",
        query: { hotelId: this.id, action: this.action },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.baidu-map-view {
  width: 100%;
  height: 500px;
  overflow-y: auto;
  .map-search {
    padding: 5px;
  }
}
</style>
